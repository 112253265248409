import React from 'react';

const Icon84Failure = () => (
  <div className="Icon Icon--84 Icon--failure_84">
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="42" cy="42" r="36" fill="url(#failure_gradient)" />
      <path d="M34 56c1-2 4-4 8-4s7 2 8 4" stroke="#99490F" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="54" cy="34" r="4" fill="#99490F" />
      <circle cx="30" cy="34" r="4" fill="#99490F" />
      <defs>
        <linearGradient id="failure_gradient" x1="6" y1="6" x2="78" y2="78" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF04D" />
          <stop offset="1" stopColor="#F83" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default Icon84Failure;
