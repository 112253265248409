import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './EmailField.css';
import * as EmailValidator from 'email-validator';
import { createClassName } from '../../tools/helpers';

const propTypes = {
  isLoading: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  sendEmail: PropTypes.func.isRequired,
  locked: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
  focusOnMount: false,
  locked: false,
};

const EmailField = (props) => {
  const {
    isLoading,
    focusOnMount,
    sendEmail,
    locked, // Temp prop to restrict sign-in
  } = props;
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const emailInputRef = useRef(null);
  const submitButtonRef = useRef(null);
  const errorAnimationDuration = 400;
  const fieldClassName = createClassName({
    EmailField: true,
    'EmailField--error': isEmailInvalid,
  });

  useEffect(() => {
    if (focusOnMount && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = () => {
    if (!locked && EmailValidator.validate(email)) {
      return true;
    }

    setIsEmailInvalid(true);

    setTimeout(() => {
      setIsEmailInvalid(false);
    }, errorAnimationDuration);

    return false;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail()) {
      return;
    }

    submitButtonRef.current.style.width = `${submitButtonRef.current.getBoundingClientRect().width}px`;
    sendEmail(email);
  };

  return (
    <form
      noValidate
      className={fieldClassName}
      onSubmit={handleFormSubmit}
      style={{
        animationDuration: isEmailInvalid ? `${errorAnimationDuration}ms` : null,
      }}
    >
      <input
        type="email"
        name="email"
        className="EmailField__input"
        placeholder="Your email"
        ref={emailInputRef}
        value={email}
        onChange={handleEmailChange}
      />
      <button
        type="submit"
        className="EmailField__submit"
        ref={submitButtonRef}
        style={{ pointerEvents: isLoading ? 'none' : null }}
      >
        {isLoading
          ? <div className="EmailField__submit_loader" />
          : <div className="EmailField__submit_text">Next</div>}
      </button>
    </form>
  );
};

EmailField.propTypes = propTypes;
EmailField.defaultProps = defaultProps;

export default EmailField;
