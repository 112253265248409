import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from 'react-router-dom';
import CoursePage from '../CoursePage/CoursePage';
import DraftsPage from '../DraftsPage/DraftsPage';
import Popups from '../Popups/Popups';

export default function App() {
  return (
    <Router>
      <RoutesSwitch />
    </Router>
  );
}

function RoutesSwitch() {
  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <Route path="/qcp">
          <CoursePage showSignIn showReviews={false} showFaq={false} />
        </Route>
        <Route path="/itsreif">
          <DraftsPage showSignIn name="Reif" tiktok="tiktok.com/@itsreif" slug="itsreif" />
        </Route>
        <Route path="/itslexismith">
          <DraftsPage showSignIn name="Lexi Smith" tiktok="tiktok.com/@itslexismith" slug="itslexismith" />
        </Route>
        <Route path="/mandipandeee">
          <DraftsPage showSignIn name="Mandi Bagley" tiktok="tiktok.com/@mandipandeee" slug="mandipandeee" />
        </Route>
        <Route path="/ummcaden02">
          <DraftsPage showSignIn name="Caden Outlaw" tiktok="tiktok.com/@ummcaden02" slug="ummcaden02" />
        </Route>
        <Route path="/itsyaboyandrewpav">
          <DraftsPage showSignIn name="Andrew Pavlunin" tiktok="tiktok.com/@itsyaboyandrewpav" slug="itsyaboyandrewpav" />
        </Route>
        <Route path="/">
          <Redirect to="/qcp" />
        </Route>
      </Switch>
      {background && <Popups />}
    </>
  );
}
