import React from 'react';
import PropTypes from 'prop-types';
import { Stream } from '@cloudflare/stream-react';

const propTypes = {
  episode: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cloudflareId: PropTypes.string,
    cover: PropTypes.string,
  }).isRequired,
};

const PaidEpisode = ({ episode }) => (
  <div className="Episode">
    <div className="Episode__player">
      <Stream
        src={episode.cloudflareId}
        poster={`${window.location.protocol}//${window.location.host}/${episode.cover}`}
        controls
      />
    </div>
    <div className="Episode__info">
      <div className="Episode__title">
        {episode.title}
      </div>
      <div className="Episode__description">
        {episode.description}
      </div>
    </div>
  </div>
);

PaidEpisode.propTypes = propTypes;

export default PaidEpisode;
