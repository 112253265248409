import React from 'react';

const Icon24Check = () => (
  <div className="Icon Icon--24 Icon--check_24">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 13l3.293 3.293a1 1 0 0 0 1.414 0L19 7" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);

export default Icon24Check;
