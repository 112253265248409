import React from 'react';

const Icon56Pause = () => (
  <div className="Icon Icon--56 Icon--pause_56">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#pause_56_clip)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.102 18.224C16 18.605 16 19.07 16 20v16c0 .93 0 1.395.102 1.776a3 3 0 0 0 2.122 2.122C18.605 40 19.07 40 20 40s1.395 0 1.776-.102a3 3 0 0 0 2.122-2.122C24 37.395 24 36.93 24 36V20c0-.93 0-1.395-.102-1.776a3 3 0 0 0-2.122-2.122C21.395 16 20.93 16 20 16s-1.395 0-1.776.102a3 3 0 0 0-2.122 2.122zm16 0C32 18.605 32 19.07 32 20v16c0 .93 0 1.395.102 1.776a3 3 0 0 0 2.122 2.122C34.605 40 35.07 40 36 40s1.395 0 1.776-.102a3 3 0 0 0 2.122-2.122C40 37.395 40 36.93 40 36V20c0-.93 0-1.395-.102-1.776a3 3 0 0 0-2.122-2.122C37.395 16 36.93 16 36 16s-1.395 0-1.776.102a3 3 0 0 0-2.122 2.122z"
          fill="#fff"
          filter="url(#pause_56_filter)"
        />
      </g>
      <defs>
        <filter id="pause_56_filter" x="0" y="4" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="pause_56_clip">
          <path fill="#fff" d="M0 0H56V56H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default Icon56Pause;
