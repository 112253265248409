import React, { useState } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Reviews.css';
import AliceCarousel from 'react-alice-carousel';
import Review from './Review';
import { isDesktop, isMobile } from '../../../tools/helpers';

const reviews = [
  {
    text: 'After completing a large project 5 years ago, I set my camera down. It’s taken me this long to pull down the wall I created around photographing people and come back to it. Annie’s class has helped me to, basically, start over again.',
    author: {
      avatar: require('../../../assets/images/reviews_Robert.png').default,
      name: 'Robert',
      network: {
        name: 'TikTok',
        followers_count: '16K',
      },
    },
  },
  {
    text: 'I was about to give up on my dreams when I started Hans Zimmer’s class. It was like someone gave me a slap in the face. He said, “are you wasting your life or are you not wasting your life? And that’s when I realized. I dont want to waste another minute of it.',
    author: {
      avatar: require('../../../assets/images/reviews_Natalie.png').default,
      name: 'Natalie',
      network: {
        name: 'TikTok',
        followers_count: '24K',
      },
    },
  },
  {
    text: 'I’ve learned a positively immeasurable amount of things from Timbaland and Natalie Portman. It gave me this sense of possibility. Like I can actually do it. I can get a Billboard hit single, and it\'s not that far away.',
    author: {
      avatar: require('../../../assets/images/reviews_Jessica.png').default,
      name: 'Jessica',
      network: {
        name: 'Instagram',
        followers_count: '12K',
      },
    },
  },
];
const DRAG_DIRECTION_VERTICAL = 'vertical';
const DRAG_DIRECTION_HORIZONTAL = 'horizontal';

const Reviews = () => {
  const [isDesktopVersion, setIsDesktopVersion] = useState(isDesktop());
  const [isMobileVersion, setIsMobileVersion] = useState(isMobile());
  const galleryPaddingSmall = isMobileVersion ? 24 : 72;
  const galleryPadding = isDesktopVersion ? 415 : galleryPaddingSmall;
  const animationDuration = 500;
  const [dragDirection, setDragDirection] = useState(null);
  const [xOffset, setXOffset] = useState(0);
  const [yOffset, setYOffset] = useState(0);

  const isVerticalDrag = () => dragDirection === DRAG_DIRECTION_VERTICAL;
  const isHorizontalDrag = () => dragDirection === DRAG_DIRECTION_HORIZONTAL;

  const handleDragStart = (event) => event.preventDefault();

  const onCarouselInitialized = () => {
    const carouselContentEl = document.querySelector('.alice-carousel__stage');
    const reviewsListEl = document.querySelector('.Reviews__list');
    const transitionOffClassName = 'Reviews__list--transition-off';

    if (!carouselContentEl || !reviewsListEl) {
      return;
    }

    carouselContentEl.addEventListener('transitionend', () => {
      const transitionDuration = parseFloat(
        getComputedStyle(carouselContentEl).transitionDuration,
      );

      if (transitionDuration === 0 && !reviewsListEl.classList.contains(transitionOffClassName)) {
        reviewsListEl.classList.add(transitionOffClassName);
      }

      setTimeout(() => {
        if (reviewsListEl.classList.contains(transitionOffClassName)) {
          reviewsListEl.classList.remove(transitionOffClassName);
        }
      }, animationDuration);
    }, false);
  };

  const onGalleryResized = () => {
    setIsDesktopVersion(isDesktop());
    setIsMobileVersion(isMobile());
  };

  const handleTouchStart = (event) => {
    const { clientX, clientY } = event.touches[0];

    setXOffset(clientX);
    setYOffset(clientY);
  };

  const handleTouchMove = (event) => {
    if (dragDirection === null) {
      const { clientX, clientY } = event.changedTouches[0];

      const xDiff = Math.abs(clientX - xOffset);
      const yDiff = Math.abs(clientY - yOffset);

      // Чтобы заблокировать вертикальный скролл, когда происходит свайп слайдов
      setDragDirection(xDiff > yDiff
        ? DRAG_DIRECTION_HORIZONTAL
        : DRAG_DIRECTION_VERTICAL);
    }
  };

  const handleTouchEnd = () => {
    setDragDirection(null);
    setXOffset(0);
    setYOffset(0);
  };

  const renderNavArrow = (type) => (
    <div className={`Reviews__slider_arrow Reviews__slider_arrow--${type}`}>
      <div className="Reviews__slider_arrow_bg" />
      <div className="Reviews__slider_arrow_icon" />
    </div>
  );

  return (
    <div className="Reviews">
      <div className="Reviews__inner">
        <div className="Reviews__header">
          <div className="Reviews__title">
            Reviews
          </div>
        </div>
        <div
          className="Reviews__list"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="Reviews__list_inner">
            <AliceCarousel
              infinite
              disableDotsControls
              animationDuration={animationDuration}
              animationEasingFunction="ease-in-out"
              paddingLeft={galleryPadding}
              paddingRight={galleryPadding}
              autoWidth={!isDesktopVersion}
              mouseTracking={!isDesktopVersion && !isVerticalDrag()}
              touchMoveDefaultEvents={isDesktopVersion || !isHorizontalDrag()}
              onResized={onGalleryResized}
              onInitialized={onCarouselInitialized}
              renderPrevButton={() => renderNavArrow('prev')}
              renderNextButton={() => renderNavArrow('next')}
              responsive={{
                0: { items: 1 },
                640: { items: 2 },
                1024: { items: 3 },
              }}
              items={[...reviews, ...reviews].map((review, index) => (
                <Review review={review} key={index} onDragStart={handleDragStart} />))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
