import React from 'react';

const Icon24Close = () => (
  <div className="Icon Icon--24 Icon--close_24">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.292 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.413 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.585 12 5.292 6.707a1 1 0 0 1 0-1.414z"
        fill="#fff"
      />
    </svg>
  </div>
);

export default Icon24Close;
