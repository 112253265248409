import React from 'react';
import { useSelector } from 'react-redux';
import './Drafts.css';
import {
  selectDrafts,
} from '../../../store/draftsModule/draftsSelectors';
import Draft from './Draft';

const Drafts = () => {
  const drafts = useSelector(selectDrafts);

  return (
    <div className="Drafts">
      <div className="Drafts__list">
        {drafts.map((draft, index) => (
          <Draft
            key={index}
            draft={draft}
          />
        ))}
      </div>
    </div>
  );
};

export default Drafts;
