import React from 'react';

const Icon24Settings = () => (
  <div className="Icon Icon--24 Icon--settings_24">
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.956 2.721a.994.994 0 0 0-1.912 0l-.548 1.919a1.092 1.092 0 0 1-.718.72c-.31.104-.612.23-.901.374-.322.16-.703.173-1.017-.002l-1.745-.97a.994.994 0 0 0-1.352 1.353l.97 1.745c.174.314.161.695 0 1.017-.144.29-.269.59-.373.9-.114.341-.374.62-.72.719l-1.919.548a.994.994 0 0 0 0 1.912l1.919.548c.346.099.606.378.72.719.104.31.229.61.373.9.161.321.174.703 0 1.017l-.97 1.745a.994.994 0 0 0 1.352 1.352l1.745-.97a1.092 1.092 0 0 1 1.017 0c.29.144.59.269.9.373.341.114.62.375.719.72l.548 1.919a.994.994 0 0 0 1.912 0l.548-1.919c.099-.345.378-.606.719-.72a6.96 6.96 0 0 0 .9-.374c.322-.16.703-.173 1.017.002l1.745.97a.994.994 0 0 0 1.352-1.353l-.97-1.745a1.092 1.092 0 0 1 0-1.017c.144-.29.269-.59.373-.9.114-.341.375-.62.72-.719l1.919-.548a.994.994 0 0 0 0-1.912l-1.919-.548a1.092 1.092 0 0 1-.72-.718 6.942 6.942 0 0 0-.373-.9 1.092 1.092 0 0 1 0-1.018l.97-1.745a.994.994 0 0 0-1.352-1.352l-1.745.97a1.092 1.092 0 0 1-1.017 0c-.29-.144-.59-.269-.9-.373a1.092 1.092 0 0 1-.719-.72l-.548-1.919zM12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10z"
        fill="#fff"
      />
    </svg>
  </div>
);

export default Icon24Settings;
