import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Purchase.css';
import {
  STATUS_ERROR,
  STATUS_IDLE,
  STATUS_LOADING,
} from '../../constants/fetch-statuses';
import EmailField from '../EmailField/EmailField';
import PopupForm from '../PopupForm/PopupForm';
import Icon84Email from '../Icons/84/Icon84Email';
import { stripePromise } from '../../tools/helpers';

const Purchase = () => {
  const location = useLocation();
  const [formStatus, setFormStatus] = useState(STATUS_IDLE);
  const bodyRef = useRef(null);

  const isFormLoading = () => formStatus === STATUS_LOADING;

  const sendForm = async (email) => {
    setFormStatus(STATUS_LOADING);

    bodyRef.current.style.height = `${bodyRef.current.getBoundingClientRect().height}px`;

    let checkoutSessionUrl;
    let body;
    if (location.state.draftsSlug !== undefined) {
      checkoutSessionUrl = 'https://app.clic.so/checkoutSession/premiumVideos';
      body = { email, slug: location.state.draftsSlug };
    } else {
      checkoutSessionUrl = 'https://app.clic.so/checkoutSession';
      body = { email, purchase_type: 'qcp' };
    }

    try {
      const stripe = await stripePromise;
      const response = await fetch(checkoutSessionUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const session = await response.json();
      if (session.status !== 'success') {
        setFormStatus(STATUS_ERROR);
        return;
      }

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        setFormStatus(STATUS_ERROR);
      } else {
        setFormStatus(STATUS_IDLE);
      }
    } catch (err) {
      setFormStatus(STATUS_ERROR);
    }
  };

  return (
    <div className="Purchase" ref={bodyRef}>
      <PopupForm
        formStatus={formStatus}
        formBody={(
          <EmailField
            focusOnMount
            isLoading={isFormLoading()}
            sendEmail={sendForm}
          />
        )}
        defaultIcon={<Icon84Email />}
        defaultHeader="Enter your email to continue"
        defaultFooter="We’ll send a payment statement and you'll be able to login with email"
        balls={[1, 2, 3]}
      />
    </div>
  );
};

export default Purchase;
