import { createReducer } from '@reduxjs/toolkit';
import {
  setAuthorInfo,
  setUserSubscribed,
  setIsListLoading,
  setList,
} from './draftsActions';

const initState = {
  subscriptionPrice: '$2',
  isUserSubscribed: false,
  isListLoading: false,
  list: [],
  isListLoaded: false,
  authorInfo: {
    name: '',
    tiktok: '',
    slug: '',
  },
};

export const drafts = createReducer(initState, (builder) => {
  builder.addCase(setIsListLoading, (state, { payload }) => {
    state.isListLoading = payload.isListLoading;
  });
  builder.addCase(setList, (state, { payload }) => {
    state.list = payload.list;
    state.isListLoading = false;
    state.isListLoaded = true;
  });
  builder.addCase(setAuthorInfo, (state, { payload }) => {
    state.authorInfo = payload.authorInfo;
  });
  builder.addCase(setUserSubscribed, (state) => {
    state.isUserSubscribed = true;
  });
});
