import React from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stream } from '@cloudflare/stream-react';
import './Promo.css';
import PaymentWays from '../../PaymentWays/PaymentWays';

const propTypes = {
  isCoursePaid: PropTypes.bool.isRequired,
  coursePrice: PropTypes.number,
  isRedirectedAfterSuccessPayment: PropTypes.bool,
};

const Promo = (props) => {
  const {
    isCoursePaid,
    coursePrice,
    isRedirectedAfterSuccessPayment,
  } = props;
  const location = useLocation();

  const renderBuyButton = () => (
    <Link
      className="Promo__cta Promo__cta--buy"
      to={{
        pathname: '/purchase',
        state: { background: location },
      }}
    >
      {`Buy course for $${coursePrice}`}
    </Link>
  );

  const renderPaymentActions = () => (
    <div className="Promo__payment">
      {renderBuyButton()}
      <PaymentWays />
    </div>
  );

  // eslint-disable-next-line no-unused-vars
  const renderPaidActions = () => (
    <a
      className="Promo__cta Promo__cta--watch"
      /* TODO: Заменить ссылку, если эта кнопка будет ссылкой, если не будет,
          то убрать атрибуты href, target, rel, заменить тэг a на div и добавить onClick */
      href="https://google.com"
      target="_blank"
      rel="noreferrer noopener"
    >
      Watch the course
    </a>
  );

  const renderButtonsConstantState = () => (isCoursePaid
    ? <></>
    : renderPaymentActions()
  );

  const renderButtonsTransientState = () => (
    <div className="Promo__buttons--transient">
      {renderBuyButton()}
    </div>
  );

  const renderSuccessBadge = () => (
    <div className="Promo__badge">
      <div className="Promo__badge_inner">
        success
      </div>
    </div>
  );

  return (
    <div className="Promo">
      <div className="Promo__video_wrap">
        <Stream
          autoplay
          loop
          muted
          poster="https://clic.so/promo_cover.png"
          preload="auto"
          className="Promo__video"
          src="25ad6e074abca34bfc9c19e18759a79a"
          height="100%"
          width="100%"
        />
      </div>
      <div className="Promo__content">
        <div className="Promo__inner">
          <div className="Promo__info">
            <div className="Promo__title">
              QCP
            </div>
            <div className="Promo__description">
              Get to know more about the real QCP.
              Learn how to make some of his signature dishes and get insider tips
              and tricks on how to make the perfect TikTok every time.
            </div>
            <div className="Promo__actions">
              {isRedirectedAfterSuccessPayment
                ? renderButtonsTransientState()
                : renderButtonsConstantState()}
            </div>
          </div>
        </div>
      </div>
      {isRedirectedAfterSuccessPayment && renderSuccessBadge()}
    </div>
  );
};

Promo.propTypes = propTypes;

export default Promo;
