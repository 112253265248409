import React from 'react';
import PropTypes from 'prop-types';
import './PopupForm.css';
import {
  STATUS_ERROR,
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '../../constants/fetch-statuses';
import Icon84Success from '../Icons/84/Icon84Success';
import Icon84Failure from '../Icons/84/Icon84Failure';

const propTypes = {
  formStatus: PropTypes.oneOf([STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR]),
  formBody: PropTypes.element,
  defaultIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  defaultHeader: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  defaultFooter: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  successHeader: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  successFooter: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  errorHeader: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  errorFooter: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  balls: PropTypes.arrayOf(PropTypes.number),
};

const defaultProps = {
  formStatus: STATUS_IDLE,
  errorHeader: 'Oops!',
  errorFooter: 'Something went wrong, please try to reload the page',
};

const PopupForm = (props) => {
  const {
    formStatus,
    formBody,
    defaultIcon,
    defaultHeader,
    defaultFooter,
    successHeader,
    successFooter,
    errorHeader,
    errorFooter,
    balls,
  } = props;

  const renderDefaultState = () => (
    <div className="PopupForm__state PopupForm__state--default">
      <div className="PopupForm__icon">
        {defaultIcon}
      </div>
      <div className="PopupForm__header">
        {defaultHeader}
      </div>
      {formBody}
      <div className="PopupForm__footer">
        {defaultFooter}
      </div>
    </div>
  );

  const renderSuccessState = () => (
    <div className="PopupForm__state PopupForm__state--success">
      <div className="PopupForm__icon">
        <Icon84Success />
      </div>
      <div className="PopupForm__header">
        {successHeader}
      </div>
      <div className="PopupForm__footer">
        {successFooter}
      </div>
    </div>
  );

  const renderErrorState = () => (
    <div className="PopupForm__state PopupForm__state--error">
      <div className="PopupForm__icon">
        <Icon84Failure />
      </div>
      <div className="PopupForm__header">
        {errorHeader}
      </div>
      <div className="PopupForm__footer">
        {errorFooter}
      </div>
    </div>
  );

  const renderState = () => {
    switch (formStatus) {
      case STATUS_SUCCESS:
        return renderSuccessState();
      case STATUS_ERROR:
        return renderErrorState();
      default:
        return renderDefaultState();
    }
  };

  return (
    <div className="PopupForm">
      <div className="PopupForm__inner">
        {renderState()}
      </div>
      <div className="PopupForm__balls">
        {balls.map((item) => <div key={item} className={`PopupForm__ball PopupForm__ball--${item}`} />)}
      </div>
    </div>
  );
};

PopupForm.propTypes = propTypes;
PopupForm.defaultProps = defaultProps;

export default PopupForm;
