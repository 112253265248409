export const selectAuthorInfo = (state) => state.drafts.authorInfo;

export const selectDrafts = (state) => state.drafts.list;

export const selectIsUserSubscribed = (state) => state.drafts.isUserSubscribed;

export const selectSubscriptionPrice = (state) => state.drafts.subscriptionPrice;

export const selectDraftIndex = (state, draftId) => state.drafts
  .list.findIndex((draft) => draft.id === draftId);
