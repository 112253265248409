import React from 'react';
import PropTypes from 'prop-types';
import './Popup.css';
import withPopupBg from '../../hoc/withPopupBg/withPopupBg';

const propTypes = {
  children: PropTypes.element,
  goBack: PropTypes.func,
};

const Popup = (props) => {
  const {
    children,
    goBack,
  } = props;

  return (
    <div className="Popup">
      <div className="Popup__inner">
        <div className="Popup__close" onClick={goBack}>
          <div className="Popup__close_inner">
            <div className="Popup__close_bg" />
            <div className="Popup__close_icon" />
          </div>
        </div>
        <div className="Popup__body">
          {children}
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = propTypes;

export default withPopupBg(Popup, 'scale-up', 'auth');
