import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import './Player.css';
import { Stream } from '@cloudflare/stream-react';
import DraftsOffers from '../Offers/DraftsOffers';
import Icon72LockBig from '../../Icons/72/Icon72LockBig';
import {
  selectAuthorInfo,
  selectDraftIndex,
  selectDrafts,
  selectSubscriptionPrice,
} from '../../../store/draftsModule/draftsSelectors';
import { createClassName, getAppHeight } from '../../../tools/helpers';
import withPopupBg from '../../../hoc/withPopupBg/withPopupBg';
import Icon24Volume from '../../Icons/24/Icon24Volume';
import Icon24Mute from '../../Icons/24/Icon24Mute';
import Icon24Close from '../../Icons/24/Icon24Close';
import Icon56Pause from '../../Icons/56/Icon56Pause';

const propTypes = {
  goBack: PropTypes.func,
};

const Player = (props) => {
  const { goBack } = props;
  const authorInfo = useSelector(selectAuthorInfo);
  const drafts = useSelector(selectDrafts);
  const subscriptionPrice = useSelector(selectSubscriptionPrice);
  const streamRef = React.useRef(null);
  const location = useLocation();
  const { draftId } = useParams();
  const [draftIndex, setDraftIndex] = useState(
    useSelector((state) => selectDraftIndex(state, parseInt(draftId, 10))),
  );
  const currentDraft = drafts[draftIndex] || (draftId === 'undefined' && drafts[0]) || {};
  const [showLoader, setShowLoader] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [tapDuration, setTapDuration] = useState(0);
  const isDraftUnlocked = currentDraft.cloudflare_id !== undefined;

  useEffect(() => {
    window.addEventListener('resize', getAppHeight);
    getAppHeight();
  }, []);

  const handleCanVideoPlay = () => {
    setShowLoader(false);

    if (isVideoMuted) {
      streamRef.current.volume = 0;
      streamRef.current.muted = true;
    }
  };

  const updateVideoTimelineProgress = () => {
    const activeTimeline = document.querySelector('.Player__timeline--active .Player__timeline_progress');

    if (activeTimeline) {
      const curProgress = (streamRef.current.currentTime / streamRef.current.duration) * 100;

      activeTimeline.style.transform = `translateX(${-101 + curProgress}%)`;
    }
  };

  const toggleVideoVolume = () => {
    if (streamRef.current.muted) {
      streamRef.current.muted = false;
    }
    streamRef.current.volume = isVideoMuted ? 1 : 0;
    streamRef.current.muted = !isVideoMuted;
    setIsVideoMuted(!isVideoMuted);
  };

  const handleMouseDownOnVideo = () => {
    setTapDuration(Date.now());
    streamRef.current.pause();
    setIsVideoPaused(true);
  };

  const handleMouseUpOnVideo = () => {
    setTapDuration(Date.now() - tapDuration);
    streamRef.current.play();
    setIsVideoPaused(false);
  };

  const goToPrevDraft = () => {
    if (draftIndex > 0) {
      setDraftIndex(draftIndex - 1);
      setShowLoader(true);
    }
  };

  const goToNextDraft = () => {
    if (draftIndex < drafts.length - 1) {
      setDraftIndex(draftIndex + 1);
      setShowLoader(true);
    }
  };

  const changeDraft = (event) => {
    if (tapDuration > 500) {
      setTapDuration(0);
      return;
    }

    const isPrevButton = event.target.classList.contains('Player__prev');
    const isFirstDraft = draftIndex === 0;
    const isLastDraft = draftIndex === drafts.length - 1;

    if ((isPrevButton && isFirstDraft) || (!isPrevButton && isLastDraft)) {
      goBack();
      return;
    }

    if (isPrevButton) {
      goToPrevDraft();
    } else {
      goToNextDraft();
    }
  };

  const renderTimeline = (draft, index) => {
    const className = createClassName({
      Player__timeline: true,
      'Player__timeline--active': index === draftIndex,
    });
    const styles = {};

    if (index < draftIndex) {
      styles.transform = 'translateX(0)';
    }

    if (index > draftIndex) {
      styles.transform = 'translateX(-101%)';
    }

    return (
      <div key={index} className={className}>
        <div
          className="Player__timeline_progress"
          style={styles}
        />
      </div>
    );
  };

  const renderPlug = () => (
    <div className="Player__plug">
      <div
        className="Player__plug_bg"
        style={{
          backgroundImage: `url(${currentDraft.preview_url})`,
        }}
      />
      <div className="Player__plug_body">
        <div className="Player__plug_icon">
          <Icon72LockBig />
        </div>
        <div className="Player__plug_title">
          Available for subscribers
        </div>
        <DraftsOffers gradientIcon={false} />
      </div>
      <div className="Player__plug_bottom">
        <Link
          className="Player__plug_cta"
          to={{
            pathname: '/purchase',
            state: {
              background: (location.state && location.state.background) || location,
              draftsSlug: authorInfo.slug,
            },
          }}
        >
          {`Unlock for ${subscriptionPrice}/mth`}
        </Link>
      </div>
    </div>
  );

  const renderDraft = () => (
    <Stream
      autoplay
      playsInline
      preload="auto"
      className="Player__video"
      width="100%"
      height="100%"
      streamRef={streamRef}
      src={currentDraft.cloudflare_id}
      poster={currentDraft.preview_url}
      onCanPlay={handleCanVideoPlay}
      onEnded={goToNextDraft}
      onTimeUpdate={updateVideoTimelineProgress}
    />
  );

  const renderLoader = () => (
    <div className="Player__loader_wrap">
      <div className="Player__loader" />
    </div>
  );

  const renderPause = () => (
    <div className="Player__paused">
      <Icon56Pause />
    </div>
  );

  const renderBody = () => (
    <>
      {renderDraft()}
      {showLoader && renderLoader()}
      {isVideoPaused && renderPause()}
      <div
        className="Player__manage"
        onClick={changeDraft}
        onMouseDown={handleMouseDownOnVideo}
        onMouseUp={handleMouseUpOnVideo}
        onTouchStart={handleMouseDownOnVideo}
        onTouchEnd={handleMouseUpOnVideo}
      >
        <div className="Player__prev" />
      </div>
    </>
  );

  const renderVolumeManageButton = () => (
    <div
      className="Player__action"
      onClick={toggleVideoVolume}
    >
      {isVideoMuted ? <Icon24Mute /> : <Icon24Volume />}
    </div>
  );

  return (
    <div className="Player">
      <div className="Player__header">
        <div className="Player__timelines">
          {drafts.map((draft, index) => renderTimeline(draft, index))}
        </div>
        <div className="Player__actions">
          {isDraftUnlocked && renderVolumeManageButton()}
          <div
            className="Player__action Player__close"
            onClick={goBack}
          >
            <Icon24Close />
          </div>
        </div>
      </div>
      <div className="Player__body">
        {isDraftUnlocked ? renderBody() : renderPlug()}
      </div>
    </div>
  );
};

Player.propTypes = propTypes;

export default withPopupBg(Player, 'opacity', 'player');
