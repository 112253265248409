import React from 'react';

const Icon16Check = () => (
  <div className="Icon Icon--16 Icon--check_16">
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8l4 4 8-8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);

export default Icon16Check;
