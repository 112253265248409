import React from 'react';

const Icon24Mute = () => (
  <div className="Icon Icon--24 Icon--mute_24">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.827 5.199c-.035.02-.115.07-.25.184-.27.226-.62.574-1.147 1.102l-1.38 1.379-.055.055c-.256.256-.492.493-.778.668a2.8 2.8 0 0 1-.867.349l4.65 4.65v-6.45c0-.748 0-1.24-.032-1.59a1.87 1.87 0 0 0-.047-.308.2.2 0 0 0-.094-.04zM17 15.586V7.088c0-.687 0-1.269-.04-1.719-.038-.427-.124-.976-.487-1.4a2.2 2.2 0 0 0-1.846-.765c-.557.044-1.006.37-1.335.646-.346.29-.758.702-1.243 1.187l-.034.034-1.379 1.378c-.345.345-.406.398-.464.433a.8.8 0 0 1-.23.096c-.066.016-.147.022-.635.022h-.893L5.207 3.793a1 1 0 0 0-1.414 1.414l15 15a1 1 0 0 0 1.414-1.414L17 15.586zM4.24 8.283L5.675 9.72a1.4 1.4 0 0 0-.023.044c-.047.093-.096.243-.124.581-.028.35-.029.806-.029 1.495v.32c0 .688 0 1.145.03 1.495.027.338.076.488.123.58a1.4 1.4 0 0 0 .611.612c.093.047.243.096.581.124.35.028.806.029 1.495.029h1.045c.363 0 .697 0 1.023.077a2.8 2.8 0 0 1 .81.336c.285.175.521.412.777.668l.056.055 1.379 1.38c.513.512.856.855 1.123 1.081l1.677 1.677a2.2 2.2 0 0 1-1.603.522c-.557-.044-1.006-.37-1.335-.646-.346-.29-.758-.702-1.243-1.187l-.034-.034-1.379-1.378c-.345-.345-.406-.398-.464-.433a.8.8 0 0 0-.23-.096c-.066-.016-.147-.022-.635-.022H8.3c-.638 0-1.177 0-1.618-.036-.462-.038-.904-.12-1.326-.335a3.4 3.4 0 0 1-1.485-1.486c-.215-.42-.297-.863-.335-1.325-.036-.441-.036-.98-.036-1.618V11.8c0-.638 0-1.177.036-1.618.038-.462.12-.904.335-1.326a3.4 3.4 0 0 1 .368-.573z"
        fill="#fff"
      />
    </svg>
  </div>
);

export default Icon24Mute;
