import React from 'react';

const Icon72Lock = () => (
  <div className="Icon Icon--72 Icon--lock_72">
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#lock_72_cip)">
        <path fillRule="evenodd" clipRule="evenodd" d="M26 26c0-5.523 4.477-10 10-10s10 4.477 10 10v6c1.977.002 3.013.027 3.816.436a4 4 0 0 1 1.748 1.748C52 35.04 52 36.16 52 38.4v7.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C48.96 52 47.84 52 45.6 52H26.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C20 48.96 20 47.84 20 45.6v-7.2c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748c.803-.41 1.84-.434 3.816-.436v-6zm16 6H30v-6a6 6 0 0 1 12 0v6z" fill="#fff" filter="url(#lock_72_filter)" />
      </g>
      <defs>
        <filter id="lock_72_filter" x="-4" y="-4" width="80" height="84" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="lock_72_cip">
          <path fill="#fff" d="M0 0H72V72H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default Icon72Lock;
