import React from 'react';
import PropTypes from 'prop-types';
import './DraftsOffers.css';
import Icon24Check from '../../Icons/24/Icon24Check';
import Icon24CheckGradient from '../../Icons/24/Icon24CheckGradient';

const propTypes = {
  gradientIcon: PropTypes.bool,
};

const defaultProps = {
  gradientIcon: true,
};

const draftsOffers = [
  { icon: 'video', text: 'Get access to never posted TikTok drafts' },
  { icon: 'notifications', text: 'New videos released every week' },
  { icon: 'money', text: 'A small monthly subscription' },
];

const DraftsOffers = ({ gradientIcon }) => {
  const renderOffer = (offer, index) => (
    <div key={index} className="DraftsOffers__offer">
      {gradientIcon ? <Icon24CheckGradient /> : <Icon24Check />}
      <div className="DraftsOffers__offer_text">
        {offer.text}
      </div>
    </div>
  );

  return (
    <div className="DraftsOffers">
      {draftsOffers.map((offer, index) => renderOffer(offer, index))}
    </div>
  );
};

DraftsOffers.propTypes = propTypes;
DraftsOffers.defaultProps = defaultProps;

export default DraftsOffers;
