import React from 'react';
import './Footer.css';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <div className="Footer">
    <div className="Footer__inner">
      <div className="Footer__copyrights">
        <div className="Footer__item">
          {`© ${currentYear} Clic Labs Inc.`}
        </div>
      </div>
      <div className="Footer__links">
        {/* <div className="Footer__item"> */}
        {/*  <a */}
        {/*    className="Footer__link" */}
        {/*    href="https://google.com" */}
        {/*    target="_blank" */}
        {/*    rel="noreferrer noopener" */}
        {/*  > */}
        {/*    Privacy policy */}
        {/*  </a> */}
        {/* </div> */}
        <div className="Footer__item">
          <a
            className="Footer__link"
            /* TODO: Заменить ссылку */
            href="mailto:team@clic.so"
            target="_blank"
            rel="noreferrer noopener"
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
