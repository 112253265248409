import React from 'react';

const balls = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const BgBalls = () => (
  <div className="CoursePage__balls">
    <div className="CoursePage__balls_inner">
      {balls.map((ball) => <div className={`CoursePage__ball CoursePage__ball--${ball}`} key={ball} />)}
    </div>
  </div>
);

export default BgBalls;
