import React from 'react';

const Icon84Sign = () => (
  <div className="Icon Icon--84 Icon--sign_84">
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 18a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v48a8 8 0 0 1-8 8H20a8 8 0 0 1-8-8V18z" fill="#2934A6" />
      <path d="M12 16.777a8 8 0 0 1 6.685-7.891l32-5.333C55.56 2.74 60 6.5 60 11.443v61.113c0 4.944-4.439 8.704-9.315 7.892l-32-5.334A8 8 0 0 1 12 67.223V16.777z" fill="url(#sign_gradient)" />
      <circle cx="48" cy="42" r="4" fill="#2934A6" />
      <defs>
        <linearGradient id="sign_gradient" x1="12" y1="2" x2="82.588" y2="44.353" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9580FF" />
          <stop offset="1" stopColor="#1D53BF" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default Icon84Sign;
