import React from 'react';

const Icon56Lock = () => (
  <div className="Icon Icon--56 Icon--lock_56">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#lock_56_clip)">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 21a8 8 0 1 1 16 0v4.038c.784.048 1.34.156 1.816.398a4 4 0 0 1 1.748 1.748C40 28.04 40 29.16 40 31.4v3.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C36.96 41 35.84 41 33.6 41H22.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C16 37.96 16 36.84 16 34.6v-3.2c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748c.475-.242 1.032-.35 1.816-.398V21zm3 4h10v-4a5 5 0 0 0-10 0v4z" fill="#fff" filter="url(#lock_56_filter)" />
      </g>
      <defs>
        <filter id="lock_56_filter" x="0" y="1" width="56" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="lock_56_clip">
          <path fill="#fff" d="M0 0H56V56H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default Icon56Lock;
