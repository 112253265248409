import React from 'react';

const Icon84Email = () => (
  <div className="Icon Icon--84 Icon--email_84">
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 32h72v35.2c0 4.48 0 6.72-.872 8.432a8 8 0 0 1-3.496 3.496C71.92 80 69.68 80 65.2 80H18.8c-4.48 0-6.72 0-8.432-.872a8 8 0 0 1-3.496-3.496C6 73.92 6 71.68 6 67.2V32z" fill="url(#email_gradient_1)" />
      <path d="M33.496 7.559c3.01-2.675 4.514-4.012 6.211-4.52a8 8 0 0 1 4.586 0c1.697.508 3.202 1.845 6.21 4.52L78 32H6L33.496 7.559z" fill="url(#email_gradient_2)" />
      <path d="M34.9 51.267L6 32h72L49.1 51.267c-2.566 1.71-3.849 2.566-5.236 2.898a8 8 0 0 1-3.728 0c-1.387-.332-2.67-1.188-5.236-2.898z" fill="#2934A6" />
      <defs>
        <linearGradient id="email_gradient_1" x1="6" y1="32" x2="50.308" y2="98.462" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9580FF" />
          <stop offset="1" stopColor="#1D53BF" />
        </linearGradient>
        <linearGradient id="email_gradient_2" x1="6" x2="29.753" y2="53.443" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9580FF" />
          <stop offset="1" stopColor="#1D53BF" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default Icon84Email;
