import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Header.css';
import Icon32Logo from '../Icons/32/Icon32Logo';
import { isDesktopDevices } from '../../tools/helpers';

const propTypes = {
  isCoursePaid: PropTypes.bool,
  showSignIn: PropTypes.bool,
  buyButtonText: PropTypes.string,
};

const Header = (props) => {
  const {
    isCoursePaid,
    showSignIn,
    buyButtonText,
  } = props;
  const headerRef = React.useRef();
  const headerBgRef = React.useRef();
  const headerButtonsContentRef = React.useRef();
  const buyButtonRef = React.useRef();
  const watchButtonRef = React.useRef();
  const location = useLocation();

  const setHeaderBgOpacity = (opacityPercent) => {
    headerBgRef.current.style.opacity = opacityPercent / 100;
  };

  const setHeaderPaddings = ({ reset = false, offset, maxPaddingTop }) => {
    headerRef.current.style.top = reset ? null : `-${offset}px`;
    headerRef.current.style.paddingBottom = reset ? null : `${maxPaddingTop - offset}px`;
  };

  const changeHeaderBgOpacity = () => {
    const maxOpacityScrollPos = 400;
    const maxOpacityPercent = 100;
    const scrollPos = window.pageYOffset;

    if (scrollPos >= maxOpacityScrollPos) {
      const currentBgOpacity = parseInt(getComputedStyle(headerBgRef.current).opacity, 10);

      if (!currentBgOpacity || currentBgOpacity * 100 < maxOpacityPercent) {
        setHeaderBgOpacity(maxOpacityPercent);
      }
    } else {
      const opacityPercent = Math.round((scrollPos / maxOpacityScrollPos) * maxOpacityPercent);
      const validOpacityPercent = opacityPercent >= maxOpacityPercent
        ? maxOpacityPercent
        : opacityPercent;

      setHeaderBgOpacity(validOpacityPercent);
    }
  };

  const changeHeaderPaddings = () => {
    const maxOpacityScrollPos = 400;
    const maxPaddingTop = 32;
    const minPaddingTop = 16;
    const paddingDiff = maxPaddingTop - minPaddingTop;
    const scrollPos = window.pageYOffset;

    if (scrollPos >= maxOpacityScrollPos) {
      const currentPaddingTop = parseInt(getComputedStyle(headerBgRef.current).paddingTop, 10);

      if (currentPaddingTop !== minPaddingTop) {
        setHeaderPaddings({ offset: minPaddingTop, maxPaddingTop });
      }
    } else {
      const offset = Math.round((scrollPos / maxOpacityScrollPos) * paddingDiff);
      const validOffset = offset >= minPaddingTop
        ? minPaddingTop
        : offset;

      setHeaderPaddings({ offset: validOffset, maxPaddingTop });
    }
  };

  const changeButtonInHeader = () => {
    const promoCta = document.querySelector('.Promo__cta');
    const hiddenButtonRef = isCoursePaid ? watchButtonRef : buyButtonRef;

    if (!promoCta || !hiddenButtonRef.current) {
      return;
    }

    const {
      top: promoCtaTop,
      height: promoCtaHeight,
    } = promoCta.getBoundingClientRect();
    const {
      top: headerTop,
      height: headerHeight,
    } = headerRef.current.getBoundingClientRect();
    const maxButtonsContentOffset = hiddenButtonRef.current.offsetTop;
    const headerAndPromoButtonDiff = (headerHeight + headerTop) - promoCtaHeight;
    let buttonsContentOffset = '0';

    if (promoCtaTop >= headerAndPromoButtonDiff) {
      buttonsContentOffset = '0';
    } else if (promoCtaTop <= headerAndPromoButtonDiff - promoCtaHeight) {
      buttonsContentOffset = `-${maxButtonsContentOffset}`;
    } else {
      const promoCtaScrolled = headerAndPromoButtonDiff - promoCtaTop;
      const offsetPercent = Math.round((promoCtaScrolled / promoCtaHeight) * 100) / 100;
      buttonsContentOffset = `-${offsetPercent * maxButtonsContentOffset}`;
    }

    headerButtonsContentRef.current.style.transform = `translateY(${buttonsContentOffset}px)`;
  };

  const handleScroll = () => {
    changeHeaderBgOpacity();
    if (isDesktopDevices()) {
      changeHeaderPaddings();
    } else if (headerRef.current.getBoundingClientRect().top < 0) {
      setHeaderPaddings({ reset: true });
    }
    changeButtonInHeader();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const renderSignInButton = () => (
    <Link
      className="Header__button Header__button--sign-in"
      to={{
        pathname: '/sign-in',
        state: { background: location },
      }}
    >
      Sign in
    </Link>
  );

  const renderBuyButton = () => (
    <Link
      className="Header__button Header__button--buy"
      ref={buyButtonRef}
      to={{
        pathname: '/purchase',
        state: { background: location },
      }}
    >
      {buyButtonText}
    </Link>
  );

  // eslint-disable-next-line no-unused-vars
  const renderWatchButton = () => (
    <a
      className="Header__button Header__button--watch"
      /* TODO: Заменить ссылку, если эта кнопка будет ссылкой, если не будет,
          то убрать атрибуты href, target, rel, заменить тэг a на div и добавить onClick */
      href="https://google.com"
      target="_blank"
      rel="noreferrer noopener"
      ref={watchButtonRef}
    >
      Watch the course
    </a>
  );

  return (
    <div className="Header" ref={headerRef}>
      <div className="Header__bg" ref={headerBgRef} />
      <div className="Header__inner">
        <div className="Header__logo">
          <Icon32Logo />
        </div>
        <div className="Header__right">
          <div className="Header__buttons">
            <div className="Header__buttons_inner">
              <div className="Header__buttons_content" ref={headerButtonsContentRef}>
                {!isCoursePaid
                  ? (
                    <>
                      {showSignIn && renderSignInButton()}
                      {renderBuyButton()}
                    </>
                  )
                  : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
