import React from 'react';

const Icon56Play = () => (
  <div className="Icon Icon--56 Icon--play_56">
    <svg width="56" height="56" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
      <filter id="play_56_filter" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="65.915" width="61.19" x="-2" y="-.958">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <clipPath id="play_56_clip">
        <path d="M0 0h56v56H0z" />
      </clipPath>
      <g clipPath="url(#play_56_clip)">
        <path d="M35.01 23.34l-8.304-5.536c-2.828-1.885-4.242-2.828-5.415-2.758a3.5 3.5 0 0 0-2.582 1.382C18 17.365 18 19.065 18 22.464v11.072c0 3.4 0 5.099.709 6.036a3.5 3.5 0 0 0 2.582 1.382c1.173.07 2.587-.873 5.415-2.758l8.305-5.536c2.337-1.558 3.505-2.337 3.912-3.32a3.5 3.5 0 0 0 0-2.68c-.407-.983-1.575-1.762-3.912-3.32z" fill="currentColor" filter="url(#play_56_filter)" />
      </g>
    </svg>
  </div>
);

export default Icon56Play;
