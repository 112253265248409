import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './DraftsPage.css';
import Header from '../Header/Header';
import BgBalls from './BgBalls';
import Footer from '../Footer/Footer';
import Author from './Author/Author';
import Drafts from './Drafts/Drafts';
import { setAuthorInfo, loadDrafts } from '../../store/draftsModule/draftsActions';
import {
  selectIsUserSubscribed,
  selectSubscriptionPrice,
} from '../../store/draftsModule/draftsSelectors';

const propTypes = {
  showSignIn: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tiktok: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

const DraftsPage = (props) => {
  const {
    showSignIn,
    name,
    tiktok,
    slug,
  } = props;
  const subscriptionPrice = useSelector(selectSubscriptionPrice);
  const isUserSubscribed = useSelector(selectIsUserSubscribed);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAuthorInfo({ authorInfo: { name, tiktok, slug } }));

    // Todo: move token to Redux-level
    const urlParams = new URLSearchParams(window.location.search);
    dispatch(loadDrafts({
      slug,
      token: window.localStorage.getItem(`token_premium_videos_${slug}`),
      loginToken: urlParams.get('loginToken'),
    }));
  }, []);

  return (
    <div className="DraftsPage">
      <div className="DraftsPage__content">
        <Header
          isCoursePaid={isUserSubscribed}
          buyButtonText={`Unlock for $${subscriptionPrice}/mth`}
          showSignIn={showSignIn}
        />
        <div className="DraftsPage__body">
          <Author slug={slug} />
          <Drafts />
        </div>
        <Footer />
      </div>
      <BgBalls />
    </div>
  );
};

DraftsPage.propTypes = propTypes;

export default DraftsPage;
