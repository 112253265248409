import { configureStore } from '@reduxjs/toolkit';
import { drafts } from './draftsModule/draftsReducer';

export const reducer = {
  drafts,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
});
