import React from 'react';
import './Faq.css';

const questions = [
  {
    id: 1,
    title: 'How much does MasterClass cost?',
    answer: 'The annual membership is 13,990 ₽ and provides unlimited access to all classes and new classes as they launch. All MasterClass memberships include access to our mobile and TV apps, high-definition videos, and downloadable class workbooks.',
  },
  {
    id: 2,
    title: 'What is included in a MasterClass membership?',
    answer: 'Membership includes access to an incredible catalog of 100+ classes taught by the world’s best on cooking, leadership, photography, writing and more. Each class includes around 20 video lessons that are 10 minutes long on average, along with an in-depth workbook. You also get access to our smartphone and TV apps, offline lessons.',
  },
  {
    id: 3,
    title: 'How does the 30-day guarantee work?',
    answer: 'Our goal is to make sure that you have the best learning experience possible. If MasterClass isn’t for you, then email us within 30 days of the date you purchase your subscription, and we’ll offer a full refund.',
  },
  {
    id: 4,
    title: 'How do I cancel?',
    answer: 'If you don’t want to continue, you can cancel your membership renewal through your account page. Follow the prompts to cancel on the Settings page, and your membership will not renew. Please note that there aren’t refunds for partially unused membership periods. If you have more questions, please contact us. We’re happy to help!',
  },
  {
    id: 5,
    title: 'Will I be charged taxes?',
    answer: 'Depending on where you live, you may be charged taxes in addition to your annual membership price. If tax applies, you will see it stated separately on your emailed invoice. Tax rates are calculated based on your location or the billing information you provided at the time of purchase. Should you have any additional questions about taxes you were charged, please view our Support Helpdesk article on taxes or contact us at support@masterclass.com',
  },
];

const Faq = () => {
  const renderQuestion = (question) => (
    <div className="Faq__question" key={question.id}>
      <div className="Faq__question_title">
        {question.title}
      </div>
      <div className="Faq__question_answer">
        {question.answer}
      </div>
    </div>
  );

  return (
    <div className="Faq">
      <div className="Faq__inner">
        <div className="Faq__header">
          <div className="Faq__title">
            FAQ
          </div>
        </div>
        <div className="Faq__questions">
          {questions.map((question) => renderQuestion(question))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
