import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const setIsListLoading = createAction('drafts/setIsListLoading');
export const setList = createAction('drafts/setList');
export const setAuthorInfo = createAction('drafts/setAuthorInfo');
export const setUserSubscribed = createAction('drafts/setUserSubscribed');

const authorInfoMock = {
  name: 'Reif',
  tiktok: 'tiktok.com/@itsreif',
  slug: 'itsreif',
};

export const loadAuthorInfo = createAsyncThunk(
  'drafts/loadAuthorInfo',
  async (_, { dispatch }) => {
    dispatch(setAuthorInfo({ authorInfo: authorInfoMock }));
  },
);

const getTokenByLoginToken = async (loginToken) => {
  const response = await fetch('https://app.clic.so/token', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ login_token: loginToken }),
  });
  const tokenInfo = await response.json();
  if (tokenInfo.success) {
    return tokenInfo.token;
  }
  return null;
};

const getPremiumVideos = async (token, slug) => {
  const response = await fetch(`https://app.clic.so/premiumVideos?slug=${slug}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status !== 200) {
    return null;
  }
  return response.json();
};

export const loadDrafts = createAsyncThunk(
  'drafts/loadDrafts',
  async ({ slug, token, loginToken }, { dispatch }) => {
    dispatch(setIsListLoading({ isListLoading: true }));

    try {
      if (token !== null) {
        const videosByToken = await getPremiumVideos(token, slug);
        if (videosByToken !== null) {
          dispatch(setList({ list: videosByToken }));
          dispatch(setUserSubscribed());
          return;
        }
      }

      if (loginToken) {
        const newToken = await getTokenByLoginToken(loginToken);
        if (newToken !== null) {
          window.localStorage.setItem(`token_premium_videos_${slug}`, newToken);
          const videosByToken = await getPremiumVideos(newToken, slug);
          if (videosByToken !== null) {
            dispatch(setList({ list: videosByToken }));
            dispatch(setUserSubscribed());
            return;
          }
        }
      }

      const previewsResponse = await fetch(`https://app.clic.so/premiumVideos/previews?slug=${slug}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const previews = await previewsResponse.json();
      dispatch(setList({ list: previews }));
    } catch (err) {
      console.warn(err);
    }
  }, {
    condition: (_, { getState }) => {
      const { isListLoading, isListLoaded } = getState().drafts;

      return !isListLoading && !isListLoaded;
    },
  },
);
