import React from 'react';
import PropTypes from 'prop-types';
import './Reviews.css';

const propTypes = {
  review: PropTypes.shape({
    text: PropTypes.string,
    author: PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
      network: PropTypes.shape({
        name: PropTypes.string,
        followers_count: PropTypes.string,
      }),
    }),
  }).isRequired,
};

const Review = ({ review: { text, author } }) => (
  <div className="Review">
    <div className="Review__inner">
      <div className="Review__text">
        {text}
      </div>
      <div className="Review__author">
        <div
          className="Review__author_avatar"
          style={{
            backgroundImage: `url(${author.avatar})`,
          }}
        />
        <div className="Review__author_info">
          <div className="Review__author_name">
            {author.name}
          </div>
          <div className="Review__author_network">
            {`${author.network.followers_count} followers on ${author.network.name}`}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Review.propTypes = propTypes;

export default Review;
