import React from 'react';

const Icon72Play = () => (
  <div className="Icon Icon--72 Icon--play_72">
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip_72_play)">
        <path d="M47.316 30.573L33.792 22.12c-3.196-1.997-4.794-2.996-6.113-2.89a4 4 0 0 0-2.89 1.602C24 21.894 24 23.778 24 27.547v16.906c0 3.769 0 5.653.789 6.715a4 4 0 0 0 2.89 1.602c1.32.106 2.917-.893 6.113-2.89l13.524-8.453c2.88-1.8 4.32-2.7 4.813-3.851a4 4 0 0 0 0-3.152c-.493-1.152-1.933-2.051-4.813-3.851z" fill="#fff" filter="url(#filter_72_play)" />
      </g>
      <defs>
        <filter id="filter_72_play" x="0" y="-.778" width="76.453" height="81.556" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="clip_72_play">
          <path fill="currentColor" d="M0 0H72V72H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default Icon72Play;
