import React from 'react';
import './Trailer.css';
import { Stream } from '@cloudflare/stream-react';

const Trailer = () => (
  <div className="Trailer">
    <div className="Trailer__header">
      <div className="Trailer__title">
        Trailer
      </div>
    </div>
    <div className="Trailer__video_wrap">
      <Stream controls src="25ad6e074abca34bfc9c19e18759a79a" />
    </div>
  </div>
);

export default Trailer;
