import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import './Author.css';
import PropTypes from 'prop-types';
import Icon16Chevron from '../../Icons/16/Icon16Chevron';
import PaymentWays from '../../PaymentWays/PaymentWays';
import Icon16Check from '../../Icons/16/Icon16Check';
import DraftsOffers from '../Offers/DraftsOffers';
import {
  selectAuthorInfo,
  selectIsUserSubscribed,
  selectSubscriptionPrice,
} from '../../../store/draftsModule/draftsSelectors';
import Icon24Settings from '../../Icons/24/Icon24Settings';

const propTypes = {
  slug: PropTypes.string.isRequired,
};

const Author = (props) => {
  const {
    slug,
  } = props;
  const location = useLocation();
  const isUserSubscribed = useSelector(selectIsUserSubscribed);
  const subscriptionPrice = useSelector(selectSubscriptionPrice);
  const authorInfo = useSelector(selectAuthorInfo);

  const openCustomerPortal = async () => {
    const token = window.localStorage.getItem(`token_premium_videos_${slug}`);
    const response = await fetch('https://app.clic.so/customerPortalSession', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ slug }),
    });
    const session = await response.json();
    window.location.href = session.url;
  };

  const renderPayment = () => (
    <>
      <div className="Author__payment">
        <Link
          className="Author__cta Author__cta--buy"
          to={{
            pathname: '/purchase',
            state: { background: location, draftsSlug: slug },
          }}
        >
          {`Unlock for ${subscriptionPrice}/month`}
        </Link>
        <PaymentWays />
      </div>
      <DraftsOffers />
    </>
  );

  const renderUnlocked = () => (
    <div className="Author__unlocked">
      <div className="Author__cta Author__cta--unlocked">
        <Icon16Check />
        Unlocked
      </div>
      <div className="Author__settings_btn" onClick={openCustomerPortal}>
        <Icon24Settings />
      </div>
    </div>
  );
  // url("../../../assets/images/author_avatar.jpeg")

  return (
    <div className="Author">
      <a
        className="Author__header"
        href={`https://${authorInfo.tiktok}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="Author__header_main">
          <div style={{ backgroundImage: `url("https://clic-public-resources.s3.us-east-2.amazonaws.com/${slug}.jpeg")` }} className="Author__avatar" />
          <div className="Author__bio">
            <div className="Author__name">
              {authorInfo.name}
            </div>
            <div className="Author__tiktok">
              {authorInfo.tiktok}
            </div>
          </div>
        </div>
        <div className="Author__chevron">
          <Icon16Chevron />
        </div>
      </a>
      {isUserSubscribed ? renderUnlocked() : renderPayment()}
    </div>
  );
};

Author.propTypes = propTypes;

export default Author;
