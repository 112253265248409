import React from 'react';

const Icon84Success = () => (
  <div className="Icon Icon--84 Icon--success_84">
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="42" cy="42" r="36" fill="url(#success_gradient)" />
      <path d="M28 44l8 8 20-20" stroke="#19805E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="success_gradient" x1="6" y1="6" x2="78" y2="78" gradientUnits="userSpaceOnUse">
          <stop stopColor="#45E6B0" />
          <stop offset="1" stopColor="#36B374" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default Icon84Success;
