import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './withPopupBg.css';
import { CSSTransition } from 'react-transition-group';
import { POPUP_ANIMATION_DURATION } from '../../constants/animations';
import { createClassName } from '../../tools/helpers';

export default function withPopupBg(WrappedComponent, appearanceType = 'opacity', wrappedComponentName = '') {
  return (props) => {
    const [isShown, setIsShown] = useState(false);
    const popupWrapInnerRef = useRef(null);
    const popupWrapWindowRef = useRef(null);
    const history = useHistory();
    const className = createClassName({
      PopupWrap: true,
      [`PopupWrap--${wrappedComponentName}`]: !!wrappedComponentName,
    });
    const windowClassName = createClassName({
      PopupWrap__window: true,
      [`PopupWrap__window--${appearanceType}`]: true,
    });

    useEffect(() => {
      setIsShown(true);
    }, []);

    const goBack = () => {
      setIsShown(false);
      setTimeout(() => {
        history.goBack();
      }, POPUP_ANIMATION_DURATION);
    };

    const hideByBgClick = (event) => {
      const { target } = event;

      if (target.classList.contains('PopupWrap__inner') || target.classList.contains('PopupWrap__window')) {
        goBack();
      }
    };

    const hideByEscButton = (event) => {
      if (event.keyCode === 27) {
        goBack();
      }
    };

    useEffect(() => {
      document.addEventListener('keyup', hideByEscButton);

      if (popupWrapWindowRef.current && popupWrapInnerRef.current) {
        const isBiggerThenWindow = popupWrapWindowRef.current.offsetHeight < window.innerHeight;

        popupWrapInnerRef.current.style.alignItems = isBiggerThenWindow
          ? 'center'
          : null;
      }

      return () => {
        document.removeEventListener('keyup', hideByEscButton);
      };
    });

    return (
      <CSSTransition
        in={isShown}
        timeout={POPUP_ANIMATION_DURATION}
        classNames="popup-wrap"
        unmountOnExit
      >
        <div className={className}>
          <div
            className="PopupWrap__bg"
            style={{ transitionDuration: `${POPUP_ANIMATION_DURATION}ms` }}
          />
          <div
            className="PopupWrap__inner"
            ref={popupWrapInnerRef}
            onClick={hideByBgClick}
          >
            <div
              className={windowClassName}
              ref={popupWrapWindowRef}
              style={{
                transitionDuration: `${POPUP_ANIMATION_DURATION}ms`,
                animationDuration: `${POPUP_ANIMATION_DURATION}ms`,
              }}
            >
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <WrappedComponent goBack={goBack} {...props} />
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  };
}
