import React from 'react';

const Icon72LockBig = () => (
  <div className="Icon Icon--72 Icon--lock_big_72">
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M24 26c0-6.627 5.373-12 12-12s12 5.373 12 12v4c1.977.002 3.013.027 3.816.436a4 4 0 0 1 1.748 1.748C54 33.04 54 34.16 54 36.4v11.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C50.96 54 49.84 54 47.6 54H24.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C18 50.96 18 49.84 18 47.6V36.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748c.803-.41 1.84-.434 3.816-.436v-4zm4 4h16v-4a8 8 0 1 0-16 0v4z" fill="#fff" />
    </svg>
  </div>
);

export default Icon72LockBig;
