import React, { useRef, useState } from 'react';
import './SignIn.css';
import { useLocation } from 'react-router-dom';
import {
  STATUS_ERROR,
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '../../constants/fetch-statuses';
import EmailField from '../EmailField/EmailField';
import PopupForm from '../PopupForm/PopupForm';
import Icon84Sign from '../Icons/84/Icon84Sign';

const SignIn = () => {
  const location = useLocation();
  const [formStatus, setFormStatus] = useState(STATUS_IDLE);
  const [email, setEmail] = useState('');
  const bodyRef = useRef(null);

  const isFormLoading = () => formStatus === STATUS_LOADING;

  const sendForm = async (signInEmail) => {
    setFormStatus(STATUS_LOADING);

    const response = await fetch('https://app.clic.so/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: signInEmail,
        redirect_slug: location.state.background.pathname.substr(1),
      }),
    });
    const loginInfo = await response.json();

    bodyRef.current.style.height = `${bodyRef.current.getBoundingClientRect().height}px`;

    if (loginInfo.status === 'sent_email') {
      setFormStatus(STATUS_SUCCESS);
    } else {
      setEmail(signInEmail);
      setFormStatus(STATUS_ERROR);
    }
  };

  const backToDefaultState = () => {
    setFormStatus(STATUS_IDLE);
  };

  const renderErrorFooter = () => (
    <>
      <div className="SignIn__error_footer">
        <div className="SignIn__error_text">
          {`Looks like you’ve never bought any courses for ${email}. Check your email or `}
          <a
            className="SignIn__link"
            href="mailto:team@clic.so"
            target="_blank"
            rel="noreferrer noopener"
          >
            contact us
          </a>
        </div>
        <div
          className="SignIn__error_button"
          onClick={backToDefaultState}
        >
          Change email
        </div>
      </div>
    </>
  );

  return (
    <div className="SignIn" ref={bodyRef}>
      <PopupForm
        formStatus={formStatus}
        formBody={(
          <EmailField
            focusOnMount
            isLoading={isFormLoading()}
            sendEmail={sendForm}
          />
        )}
        defaultIcon={<Icon84Sign />}
        defaultHeader="Enter your email to sign in"
        defaultFooter="We’ll send you an access link"
        successHeader="Link sent!"
        successFooter="We sent you a login link to your email address."
        errorHeader="Oops! Can’t find your email"
        errorFooter={renderErrorFooter()}
        balls={[1, 2, 3]}
      />
    </div>
  );
};

export default SignIn;
