import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import Popup from '../Popup/Popup';
import Purchase from '../Purchase/Purchase';
import SignIn from '../SignIn/SignIn';
import { POPUP_ANIMATION_DURATION } from '../../constants/animations';
import Player from '../DraftsPage/Player/Player';
import { isMobileDevices } from '../../tools/helpers';

const Popups = () => {
  const [currentScroll, setCurrentScroll] = useState(0);

  const lockScroll = () => {
    setCurrentScroll(window.pageYOffset);

    setTimeout(() => {
      document.body.classList.add('scroll-blocked');
      if (isMobileDevices()) {
        document.body.style.top = `-${window.pageYOffset}px`;
      }
    }, POPUP_ANIMATION_DURATION);
  };

  const unlockScroll = () => {
    document.body.classList.remove('scroll-blocked');
    document.body.style.top = null;
    window.scrollTo(0, currentScroll);
  };

  useEffect(() => {
    lockScroll();

    return () => unlockScroll();
  }, []);

  return (
    <>
      <Route path="/purchase">
        <Popup id="purchase">
          <Purchase />
        </Popup>
      </Route>
      <Route path="/sign-in">
        <Popup id="sign-in">
          <SignIn />
        </Popup>
      </Route>
      <Route path="/player/:draftId">
        <Player />
      </Route>
    </>
  );
};

export default Popups;
