import React from 'react';

const Icon24CheckGradient = () => (
  <div className="Icon Icon--24 Icon--check_gradient_24">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 13l3.293 3.293a1 1 0 0 0 1.414 0L19 7" stroke="url(#check_24_gradient)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="check_24_gradient" x1="5" y1="7" x2="14.46" y2="20.243" gradientUnits="userSpaceOnUse">
          <stop stopColor="#45E6B0" />
          <stop offset="1" stopColor="#36B374" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default Icon24CheckGradient;
