import React from 'react';
import './Episodes.css';
import PropTypes from 'prop-types';
import Episode from './Episode';
import PaidEpisode from './PaidEpisode';

const episodes = [
  {
    title: 'Q’s Story',
    description: 'Learn more about QCP’s personal life, rise to fame, and how he first started creating content on TikTok.',
    duration: 6,
    cover: require('../../../assets/images/episodes_cover_1.png').default,
  },
  {
    title: 'Chicken Fettuccine Alfredo',
    description: 'Master the dish that helped QCP first go viral on TikTok: chicken fettuccine alfredo.',
    duration: 5,
    cover: require('../../../assets/images/episodes_cover_2.png').default,
  },
  {
    title: 'Rigatoni Buttera',
    description: 'Keep cooking and learn how to make another one of QCP’s specialties: rigatoni buttera.',
    duration: 5,
    cover: require('../../../assets/images/episodes_cover_3.png').default,
  },
  {
    title: 'Mastering TikToks',
    description: 'Take your TikToks to the next level and learn industry secrets on how to be an influencer and make the best content possible.',
    duration: 5,
    cover: require('../../../assets/images/episodes_cover_4.png').default,
  },
];

const propTypes = {
  isCoursePaid: PropTypes.bool,
  episodeTitleToCloudflareId: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

const Episodes = ({ isCoursePaid, episodeTitleToCloudflareId }) => (
  <div className="Episodes">
    <div className="Episodes__header">
      <div className="Episodes__title">
        Episodes
      </div>
    </div>
    <div className="Episodes__list">
      {episodes
        .map((episode, index) => (
          isCoursePaid ? (
            <PaidEpisode
              episode={{ ...episode, cloudflareId: episodeTitleToCloudflareId[episode.title] }}
              key={index}
              index={index + 1}
            />
          )
            : <Episode episode={episode} key={index} index={index + 1} />
        ))}
    </div>
  </div>
);

Episodes.propTypes = propTypes;

export default Episodes;
