import React from 'react';

const Icon24Volume = () => (
  <div className="Icon Icon--24 Icon--volume_24">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.827 5.199c-.035.02-.115.07-.25.184-.27.227-.62.574-1.147 1.102l-1.38 1.379-.055.055c-.256.256-.492.493-.778.668a2.8 2.8 0 0 1-.809.336C10.082 9 9.748 9 9.385 9H8.34c-.689 0-1.145 0-1.495.03-.338.027-.488.076-.58.123a1.4 1.4 0 0 0-.612.611c-.047.093-.096.243-.124.581-.028.35-.029.806-.029 1.495v.32c0 .689 0 1.145.03 1.495.027.338.076.488.123.58a1.4 1.4 0 0 0 .611.612c.093.047.243.096.581.124.35.028.806.029 1.495.029h1.045c.363 0 .697 0 1.023.077a2.8 2.8 0 0 1 .81.336c.285.175.521.412.777.668l.056.055 1.379 1.38c.527.527.876.875 1.146 1.1.136.115.216.165.25.185a.201.201 0 0 0 .095-.039c.01-.039.031-.13.047-.308.031-.35.032-.843.032-1.59V7.136c0-.746-.001-1.238-.032-1.59a1.87 1.87 0 0 0-.047-.307.2.2 0 0 0-.094-.04zm-.2-1.995a2.2 2.2 0 0 1 1.846.764c.363.425.45.974.487 1.401.04.45.04 1.032.04 1.719v.003c2.526.473 4 2.716 4 4.909 0 2.193-1.474 4.436-4 4.91v.002c0 .687 0 1.269-.04 1.719-.038.427-.124.976-.487 1.4a2.2 2.2 0 0 1-1.846.765c-.557-.044-1.006-.37-1.335-.646-.346-.29-.758-.702-1.243-1.187l-1.413-1.412c-.345-.345-.406-.398-.464-.433a.8.8 0 0 0-.23-.096c-.066-.016-.147-.022-.635-.022H8.3c-.638 0-1.177 0-1.618-.036-.462-.038-.904-.12-1.326-.335a3.4 3.4 0 0 1-1.485-1.485c-.215-.422-.297-.864-.335-1.326-.036-.441-.036-.98-.036-1.618v-.4c0-.638 0-1.177.036-1.618.038-.462.12-.904.335-1.326a3.4 3.4 0 0 1 1.485-1.485c.422-.215.864-.297 1.326-.335C7.123 7 7.662 7 8.3 7h1.007c.488 0 .569-.006.634-.022a.8.8 0 0 0 .231-.096c.058-.035.12-.088.464-.433l1.413-1.412c.485-.485.897-.897 1.243-1.187.33-.276.778-.602 1.335-.646zM17 14.844c1.264-.415 2-1.61 2-2.844 0-1.235-.736-2.428-2-2.845v5.69z"
        fill="#fff"
      />
    </svg>
  </div>
);

export default Icon24Volume;
