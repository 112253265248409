import React from 'react';
import './Content.css';
import PropTypes from 'prop-types';
import Author from '../Author/Author';
import Trailer from '../Trailer/Trailer';
import Episodes from '../Episodes/Episodes';

const propTypes = {
  isCoursePaid: PropTypes.bool,
  episodeTitleToCloudflareId: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

const Content = ({ isCoursePaid, episodeTitleToCloudflareId }) => (
  <div className="Content">
    <div className="Content__inner">
      <div className="Content__left">
        <Trailer />
        <Episodes
          isCoursePaid={isCoursePaid}
          episodeTitleToCloudflareId={episodeTitleToCloudflareId}
        />
      </div>
      <Author />
    </div>
  </div>
);

Content.propTypes = propTypes;

export default Content;
