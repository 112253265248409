import React, { useEffect, useState } from 'react';
import './CoursePage.css';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import Promo from './Promo/Promo';
import Content from './Content/Content';
import Reviews from './Reviews/Reviews';
import Faq from './Faq/Faq';
import Footer from '../Footer/Footer';
import BgBalls from './BgBalls';

const coursePrice = 9.99;

// TODO: такое условие просто, чтобы проверить состояние, в эту переменную нужно положить true,
//  чтобы была анимация с успещной оплатой и сменой кнопок
const isRedirectedAfterSuccessPayment = window.location.hash.indexOf('payment-success') !== -1;

const propTypes = {
  showSignIn: PropTypes.bool,
  showReviews: PropTypes.bool,
  showFaq: PropTypes.bool,
};

const CoursePage = (props) => {
  const {
    showSignIn,
    showReviews,
    showFaq,
  } = props;

  const [isPaid, setIsPaid] = useState(false);
  const [episodeTitleToCloudflareId, setEpisodeTitleToCloudflareId] = useState({});

  /*
   Temporary hack. In the future, all episode data should depend on API (not just cloudflareId).
  */

  const requestEpisodes = async (token) => {
    const response = await fetch('https://app.clic.so/courses/qcp/episodes', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();

    if (result.paid) {
      const newTitleMapping = Object.assign(
        {}, ...result.episodes.map((episode) => ({ [episode.title]: episode.cloudflare_id })),
      );
      setEpisodeTitleToCloudflareId(newTitleMapping);
    }

    setIsPaid(result.paid);

    return result.paid;
  };

  useEffect(async () => {
    const token = window.localStorage.getItem('token');
    if (token !== null) {
      const paid = await requestEpisodes(token);
      if (paid) {
        return;
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const loginToken = urlParams.get('loginToken');
    if (loginToken) {
      const response = await fetch('https://app.clic.so/token', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login_token: loginToken }),
      });
      const tokenInfo = await response.json();
      if (tokenInfo.success) {
        window.localStorage.setItem('token', tokenInfo.token);
        await requestEpisodes(tokenInfo.token);
      }
    }
  }, []);

  return (
    <div className="CoursePage">
      <div className="CoursePage__content">
        <Header
          isCoursePaid={isPaid || isRedirectedAfterSuccessPayment}
          showSignIn={showSignIn}
          buyButtonText={`Buy for $${coursePrice}`}
        />
        <Promo
          isCoursePaid={isPaid}
          coursePrice={coursePrice}
          isRedirectedAfterSuccessPayment={isRedirectedAfterSuccessPayment}
        />
        <Content
          isCoursePaid={isPaid}
          episodeTitleToCloudflareId={episodeTitleToCloudflareId}
        />
        {showReviews && <Reviews />}
        {showFaq && <Faq />}
        <Footer />
      </div>
      <BgBalls />
    </div>
  );
};

CoursePage.propTypes = propTypes;

export default CoursePage;
