import { loadStripe } from '@stripe/stripe-js';
import { BREAKPOINT_DESKTOP, BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../constants/breakpoints';

export const stripePromise = loadStripe('pk_live_51HVJVtBaaHuuZRoBLAKwvt13wA6CdElHwezwuefkqhy4tNd5Gk9BxbHrLn0YdHe42ZqOS2XRRRjruI7TI7MihZFv00neSbfdNc');

export function hasLength(item) {
  return item && item.length;
}

export function createClassName(object) {
  const str = [];

  Object.keys(object).forEach((key) => {
    if (object.hasOwnProperty(key)) {
      if (object[key]) {
        str.push(key);
      }
    }
  });

  return str.join(' ');
}

export function isMobile() {
  return window.innerWidth < BREAKPOINT_MOBILE;
}

export function isMobileDevices() {
  return window.innerWidth < BREAKPOINT_TABLET;
}

export function isDesktopDevices() {
  return window.innerWidth >= BREAKPOINT_TABLET;
}

export function isDesktop() {
  return window.innerWidth >= BREAKPOINT_DESKTOP;
}

export function getAppHeight() {
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`,
  );
}
