import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { createClassName, isDesktop } from '../../../tools/helpers';
import Icon72Play from '../../Icons/72/Icon72Play';
import Icon56Play from '../../Icons/56/Icon56Play';
import Icon72Lock from '../../Icons/72/Icon72Lock';
import Icon56Lock from '../../Icons/56/Icon56Lock';

const propTypes = {
  draft: PropTypes.shape({
    id: PropTypes.number,
    cloudflare_id: PropTypes.string,
    preview_url: PropTypes.string,
  }).isRequired,
};

const Draft = (props) => {
  const {
    draft,
  } = props;
  const location = useLocation();
  const isFirstDraftWithPreview = draft.preview_url !== undefined;
  const isUnlocked = isFirstDraftWithPreview || draft.cloudflare_id !== undefined;
  const className = createClassName({
    Drafts__draft: true,
    'Drafts__draft--locked': !isUnlocked,
  });

  const renderPlayIcon = () => (
    isDesktop
      ? <Icon72Play />
      : <Icon56Play />
  );

  const renderLockIcon = () => (
    isDesktop()
      ? <Icon72Lock />
      : <Icon56Lock />
  );

  return (
    <Link
      className={className}
      to={{
        pathname: `/player/${draft.id}`,
        state: { background: location },
      }}
    >
      <div
        className="Drafts__draft_bg"
        style={{
          backgroundImage: `url('${draft.preview_url}')`,
        }}
      />
      <div className="Drafts__draft_icon">
        {isUnlocked ? renderPlayIcon() : renderLockIcon()}
      </div>
    </Link>
  );
};

Draft.propTypes = propTypes;

export default Draft;
