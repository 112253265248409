import React from 'react';

const balls = [1, 2, 3];

const BgBalls = () => (
  <div className="DraftsPage__balls">
    <div className="DraftsPage__balls_inner">
      {balls.map((ball) => <div className={`DraftsPage__ball DraftsPage__ball--${ball}`} key={ball} />)}
    </div>
  </div>
);

export default BgBalls;
