import React from 'react';
import './PaymentWays.css';
import Icon32ApplePay from '../Icons/32/Icon32ApplePay';
import Icon32Mastercard from '../Icons/32/Icon32Mastercard';
import Icon32Visa from '../Icons/32/Icon32Visa';

const APPLE_PAY = 'apple_pay';
const MASTERCARD = 'mastercard';
const VISA = 'visa';
const PAYMENT_WAYS = [APPLE_PAY, MASTERCARD, VISA];

const PaymentWays = () => {
  const renderPaymentWayIcon = (way) => {
    switch (way) {
      case APPLE_PAY:
        return <Icon32ApplePay />;
      case MASTERCARD:
        return <Icon32Mastercard />;
      case VISA:
        return <Icon32Visa />;
      default:
        return null;
    }
  };

  const renderPaymentWay = (way) => (
    <div className="PaymentWays__way" key={way}>
      {renderPaymentWayIcon(way)}
    </div>
  );

  return (
    <div className="PaymentWays">
      {PAYMENT_WAYS
        .map((way) => renderPaymentWay(way))}
    </div>
  );
};

export default PaymentWays;
