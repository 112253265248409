import React from 'react';
import './Author.css';
import Icon16Chevron from '../../Icons/16/Icon16Chevron';
import Icon32TikTok from '../../Icons/32/Icon32TikTok';
import Icon32Instagram from '../../Icons/32/Icon32Instagram';
import Icon72Play from '../../Icons/72/Icon72Play';
import Icon56Play from '../../Icons/56/Icon56Play';

const VIDEO_SIZE_BIG = 'big';
const VIDEO_SIZE_SMALL = 'small';

const NETWORK_TIK_TOK = 'TikTok';
const NETWORK_INSTAGRAM = 'Instagram';

const videos = [
  {
    url: 'https://vm.tiktok.com/ZMecWgHj3/',
    cover: require('../../../assets/images/author_video_1.png').default,
  },
  {
    url: 'https://vm.tiktok.com/ZMecWXMqK/',
    cover: require('../../../assets/images/author_video_2.png').default,
  },
  {
    url: 'https://vm.tiktok.com/ZMecWXk1p/',
    cover: require('../../../assets/images/author_video_3.png').default,
  },
];

const networks = [
  {
    name: NETWORK_TIK_TOK,
    followers_count: '7.3M',
    url: 'https://www.tiktok.com/@itsqcp',
  },
  {
    name: NETWORK_INSTAGRAM,
    followers_count: '183K',
    url: 'https://www.instagram.com/itsqcp/?hl=en',
  },
];

const Author = () => {
  const renderVideo = (video, size) => (
    <a
      className={`Author__video Author__video--${size}`}
      href={video.url}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div
        className="Author__video_bg"
        style={{
          backgroundImage: `url(${video.cover})`,
        }}
      />
      <div className="Author__video_button">
        {size === VIDEO_SIZE_BIG ? <Icon72Play /> : <Icon56Play />}
      </div>
    </a>
  );

  const renderNetworkLogo = (networkName) => {
    switch (networkName) {
      case NETWORK_TIK_TOK:
        return <Icon32TikTok />;
      case NETWORK_INSTAGRAM:
        return <Icon32Instagram />;
      default:
        return null;
    }
  };

  const renderNetwork = (network, index) => (
    <a
      className="Author__network"
      key={index}
      href={network.url}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className="Author__network_icon">
        {renderNetworkLogo(network.name)}
      </div>
      <div className="Author__network_info">
        {`${network.followers_count} followers on ${network.name}`}
      </div>
      <div className="Author__network_chevron">
        <Icon16Chevron />
      </div>
    </a>
  );

  return (
    <div className="Author">
      <div className="Author__header">
        <div className="Author__title">
          Author
        </div>
      </div>
      <div className="Author__content">
        <div className="Author__videos">
          <div className="Author__videos_left">
            {renderVideo(videos[0], VIDEO_SIZE_BIG)}
          </div>
          <div className="Author__videos_right">
            {renderVideo(videos[1], VIDEO_SIZE_SMALL)}
            {renderVideo(videos[2], VIDEO_SIZE_SMALL)}
          </div>
        </div>
        <div className="Author__info">
          <div className="Author__description">
            QCP is a 21 yo internet personality from Charlotte, North Carolina,
            widely known for his cooking sketches.
          </div>
          <div className="Author__networks">
            {networks
              .map((network, index) => renderNetwork(network, index))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Author;
