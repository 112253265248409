import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  episode: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    cover: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

const Episode = ({ episode, index }) => (
  <div className="Episode">
    <div className="Episode__cover">
      <div
        className="Episode__cover_bg"
        style={{
          backgroundImage: `url(${episode.cover})`,
        }}
      />
      <div className="Episode__number">
        <div className="Episode__number_text">
          {index}
        </div>
      </div>
      <div className="Episode__duration">
        <div className="Episode__duration_text">
          {`${episode.duration} min`}
        </div>
      </div>
    </div>
    <div className="Episode__info">
      <div className="Episode__title">
        {episode.title}
      </div>
      <div className="Episode__description">
        {episode.description}
      </div>
    </div>
  </div>
);

Episode.propTypes = propTypes;

export default Episode;
